@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');


body {
	font-family: 'Poppins', sans-serif;
}

.form-header, .page-title, .label, h1, h2, h3, h4, h5, h6 {
	font-family: 'Roboto', sans-serif;
}

.navbar {
	background: linear-gradient(to left, #000000, #bc54d4, #51bbcf);
}

.navbar-home img {
	max-height: 50px;
	margin-top: -3px;
}

.nav-link {
	color: var(--heading-color);
}

#navbar-breadcrumbs a {
	color: #ffffff;
}

#navbar-breadcrumbs li.disabled a {
	color: #9e9e9e;
}

#navbar-breadcrumbs a:before {
	filter: invert(50%) sepia(100%) saturate(500%) hue-rotate(180deg);
}

:root {
	--primary: #8b2e9f;
	--secondary: #7e368d;
	--icon-stroke: #5d3a7c;
	--heading-color: #4b0082;
	--subheading-color: #5d3a7c;
	--text-color: var(--primary);
	--text-muted: var(--secondary);
	--control-bg: #f8f5f5;
	--disabled-control-bg: var(--control-bg);
	--control-bg-on-gray: var(--control-bg);
	--disabled-text-color: var(--secondary);
	--border-color: #e2d6eb;
	--table-border-color: var(--border-color);
	--checkbox-gradient: linear-gradient(180deg, var(--secondary) -124.51%, var(--secondary) 100%);
	--heading-gradient: linear-gradient(to right, var(--heading-color), #a331b9);
}

/* Child Tables / grid */
.grid-heading-row {
	background-color: var(--control-bg);
	color: var(--primary);
}

.sidebar-toggle-btn {
	--icon-stroke: var(--subheading-color) !important;
}

.sidebar-item-label {
	color: var(--heading-color);
	font-weight: 100;
}

.sidebar-section .sidebar-label {
	font-weight: bold;
}

.btn-primary {
	background-color: var(--secondary) !important;
	color: #f3f9ff !important;
}

.btn.btn-default,
.list-row-head,
.input-with-feedback
.btn-secondary {
	background-color: #f3f9ff !important;
	color: var(--secondary) !important;
}

/* List View Table */
.form-control,
.list-row-col,
.list-subject a {
	font-weight: normal;
}

/* Header color fixup */
.title-text,
.ce-header {
	font-size: 2em;
	font-weight: bold;
	background: var(--heading-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	color: transparent;
}

.level-item,
.list-row .level-left, .list-row-head .level-left {
	color: var(--heading-color);
}

/* Input field background */
.input-with-feedback
.like-disabled-input {
	background-color: var(--control-bg) !important;
}

input.input-with-feedback::-moz-placeholder {
	color: var(--secondary);
}

input.input-with-feedback::placeholder {
	color: var(--secondary);
}

.text-extra-muted {
	color: var(--secondary) !important;
}

.list-row-checkbox {
	background-color: var(--control-bg);
	border-color: #d7b7dd
}

/* Adjust headers and labels*/
.form-link-title {
	color: var(--subheading-color);
}

.widget-body {
	line-height: 20px !important;
	padding-right: 15px !important;
}

.widget-label {
	padding-right: 8px !important;
}

.widget.links-widget-box .widget-head .widget-label .widget-title {
	color: var(--subheading-color) !important;
}

/* Hover */
.link-text:hover {
	color: var(--subheading-color);
}

/* Disabled Selected Checkbox */
.disabled-selected {
	background-color: #6a546f !important;
}

* {
	scrollbar-color: var(--border-color) transparent !important;
}

.widget.links-widget-box .link-item {
	color: var(--secondary);
}

/* increase list-view row height */
.list-row {
	height: 60px;
}

.list-row-container .level-item.bold.ellipsis {
	width: 90%;
	display: block;
}

/* list-view text wrap */
.list-row-container .ellipsis a.ellipsis {
	white-space: normal;
}

.list-row-container .list-row,
.list-row-container .list-row-head {
	min-height: 40px;
	height: auto;
}

a.add-group-by, a.list-tag-preview {
	position: relative;
	display: inline-block;
	padding-right: 30px; /* Increase space for the "L" line */
}

a.add-group-by::before, a.list-tag-preview::before {
	content: "";
	position: absolute;
	right: 5px; /* Shift to the right */
	top: 50%;
	transform: translateY(-50%);
	width: 20px; /* Extend the length of the horizontal line */
	height: 2px; /* Thickness of the horizontal line */
	background-color: #000; /* Color of the line */
}

a.add-group-by::after, a.list-tag-preview::after {
	content: "";
	position: absolute;
	right: 5px; /* Align with the horizontal line */
	width: 2px; /* Thickness of the vertical line */
	height: 10px; /* Decrease the height of the vertical line */
	background-color: #000; /* Color of the line */
}

@media only screen and (max-width: 767px) {
	.page-title .title-area .title-text {
	  max-width: none;
	}
}